<template>
  <div class="main">Rent or Buy</div>
  <div class="sub">Answer the following questions</div>

  <div class="card">
    <div class="question">
      <div class="label">Enter Tenure</div>
      <el-input
        class="input"
        input-style="text-align: right;"
        v-model="tenure"
        style="width: 240px"
        placeholder="Please input"
        size="large"
        :formatter="(value) => `${value} Year(s)`"
        :parser="(value) => value.replace(' Year(s)', '')"
      />
    </div>

    <div class="question">
      <div class="label">Current Savings</div>
      <el-input
        v-model="savings"
        input-style="text-align: right;"
        style="width: 240px"
        placeholder="Please input"
        size="large"
        :formatter="
          (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        "
        :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
      />
    </div>

    <div class="question">
      <div class="label">Hosue Market Value</div>

      <el-input
        v-model="price"
        input-style="text-align: right;"
        style="width: 240px"
        size="large"
        placeholder="Please input"
        :formatter="
          (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        "
        :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
      />
    </div>

    <div class="question">
      <div class="label">Expected Down Payment</div>

      <el-input
        v-model="down"
        input-style="text-align: right;"
        style="width: 240px"
        size="large"
        placeholder="Please input"
        :formatter="
          (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        "
        :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
      />
    </div>

    <div class="question">
      <div class="label">Mortgage Interest Rate</div>

      <el-input
        v-model="interest"
        input-style="text-align: right;"
        style="width: 240px"
        size="large"
        placeholder="Please input"
        :formatter="(value) => `${value}%`"
        :parser="(value) => value.replace('%', '')"
      />
    </div>

    <div class="question">
      <div class="label">Current Rent</div>

      <el-input
        v-model="rent"
        input-style="text-align: right;"
        style="width: 240px"
        size="large"
        placeholder="Please input"
        :formatter="
          (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        "
        :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
      />
    </div>

    <div class="question">
      <el-button @click="submit" size="large" type="primary">Submit</el-button>
    </div>

    <div class="final">So after 30 years</div>
    <div>
      <p>HOA Monthly Payment: ${{ hoaPayment }}</p>
      <p>Total Monthly Payment: ${{ totalMonthlyPayment }}</p>
      <p>Investment Total Amount: ${{ investmentTotalAmount }}</p>
      <p>Investment Interest Earned: ${{ investmentInterestEarned }}</p>
    </div>
  </div>
  <div :class="graph" v-if="results">
    <apexchart
      type="line"
      :options="chartOptions"
      :series="chartSeries"
      width="60%"
      height="500"
    ></apexchart>
  </div>

  <div :class="graph" v-if="results">
    <apexchart
      type="line"
      :options="chartOptions"
      :series="chartRentSeries"
      width="60%"
      height="500"
    ></apexchart>
  </div>

  <div :class="graph" v-if="results">
    <apexchart
      type="line"
      :options="chartOptions"
      :series="chartSavingsSeries"
      width="60%"
      height="500"
    ></apexchart>
  </div>
</template>

<script>
export default {
  data() {
    return {
      results: false,
      price: 300000,
      savings: 100000,
      tenure: 30,
      interest: 7,
      down: 60000,
      hoaPayment: null,
      totalMonthlyPayment: null,
      investmentTotalAmount: null,
      investmentInterestEarned: null,
      yearlyMortgage: [],
      rent: 1200,
      yearly: null,
      chartSeries: [],
      chartRentSeries: [],
      chartSavingsSeries: [],

      chartOptions: {
        chart: {
          id: "line-chart",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          type: "category",
          title: {
            text: "Year",
          },
        },
        yaxis: {
          title: {
            text: "Total Value",
          },
        },
        title: {
          text: "Mortgage Value Over Time",
          align: "left",
        },
      },
    };
  },
  methods: {
    submit() {
      this.results = true;
      const interest = parseFloat(this.interest);
      const price = parseFloat(this.price) - parseFloat(this.down);
      const marketPrice = parseFloat(this.price);
      const tenure = parseFloat(this.tenure);
      const hoa = (0.025 * marketPrice) / 12;
      const additional = 12500;
      const investment =
        parseFloat(this.savings) - parseFloat(this.down) - additional;
      const investmentRate = 8;

      const initialValue = calculatePMT(price, interest, tenure) * 12;
      const initialAddition = 0.025 * price;

      const growthRate = 0.01; // 1% growth rate
      const rentGrowthRate = 0.03;

      const rent = parseFloat(this.rent) * 12;

      function calculatePMT(principal, annualRate, years) {
        let monthlyRate = annualRate / 100 / 12;
        let totalPayments = years * 12;
        let pmt =
          (principal * monthlyRate) /
          (1 - Math.pow(1 + monthlyRate, -totalPayments));
        return pmt.toFixed(2);
      }

      let monthlyPayment = calculatePMT(price, interest, tenure);
      let totalMonthlyPayment = parseFloat(hoa) + parseFloat(monthlyPayment);

      function calculateCompoundInterest(
        principal,
        rate,
        timesCompounded,
        years
      ) {
        let interestRate = rate / 100;
        let amount =
          principal *
          Math.pow(1 + interestRate / timesCompounded, timesCompounded * years);
        let interest = amount - principal;
        return {
          totalAmount: amount.toFixed(2),
          interestEarned: interest.toFixed(2),
        };
      }

      let money = calculateCompoundInterest(
        investment,
        investmentRate,
        1,
        tenure
      );

      // Set data properties to display in template
      this.hoaPayment = hoa.toFixed(2);
      this.totalMonthlyPayment = totalMonthlyPayment.toFixed(2);
      this.investmentTotalAmount = money.totalAmount;
      this.investmentInterestEarned = money.interestEarned;

      function calculateAnnualTotals(
        initialValue,
        initialAddition,
        growthRate,
        years
      ) {
        const results = [];
        let total = initialValue;
        let annualAddition = initialAddition;

        for (let year = 1; year <= years; year++) {
          // Add the current annual addition to the total
          total = initialValue + annualAddition;

          // Store the result for the current year
          results.push({
            year: year,
            total: total.toFixed(2),
          });

          // Increase the annual addition by the growth rate (1% per year)
          annualAddition = annualAddition + annualAddition * growthRate;
        }

        return results;
      }

      const yearlyTotals = calculateAnnualTotals(
        initialValue,
        initialAddition,
        growthRate,
        tenure
      );
      this.yearlyMortgage = yearlyTotals;
      this.chartSeries = [
        {
          name: "Total Value",
          data: yearlyTotals.map((item) => ({ x: item.year, y: item.total })),
        },
      ];

      function calculateRentOverYears(initialRent, growthRate, years) {
        const rentOverYears = [];
        let currentRent = initialRent;

        for (let year = 1; year <= years; year++) {
          rentOverYears.push({
            year: year,
            rent: currentRent.toFixed(2),
          });
          currentRent += currentRent * growthRate; // Increase rent by growth rate
        }

        return rentOverYears;
      }

      const rentData = calculateRentOverYears(rent, rentGrowthRate, tenure);

      this.chartRentSeries = [
        {
          name: "Total Value",
          data: rentData.map((item) => ({ x: item.year, y: item.rent })),
        },
      ];

      function calculateYearly(
        initialValue,
        initialAddition,
        initialRent,
        growthRate,
        rentGrowthRate,
        years
      ) {
        const results = [];

        let total = initialValue;
        let currentRent = initialRent;
        let annualAddition = initialAddition;

        for (let year = 1; year <= years; year++) {
          total = initialValue + annualAddition;
          results.push({
            year: year,
            total: total.toFixed(2),
            rent: currentRent.toFixed(2),
            savings: currentRent.toFixed(2) - total.toFixed(2),
          });

          annualAddition = annualAddition + annualAddition * growthRate;
          currentRent += currentRent * rentGrowthRate;
        }
        return results;
      }

      const yearlyNewTotals = calculateYearly(
        initialValue,
        initialAddition,
        rent,
        growthRate,
        rentGrowthRate,
        tenure
      );
      this.yearly = yearlyNewTotals;
      this.chartSavingsSeries = [
        {
          name: "Total Value",
          data: yearlyNewTotals.map((item) => ({
            x: item.year,
            y: item.savings,
          })),
        },
      ];
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0px;
}

.question {
  padding: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  padding: 10px 0px;
}
</style>

<!-- byebyebuy downpayment = downpayment + addtional compont > princinpal = saving -
downpayment - closing cost -->
